//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//////////////////////////////

export const QandA_v6B = [
    {
      id: '4',
      first_question: true,
      question: [
        `I’m here to help you unlock up to $6,300 in tax subsidies and have just a couple quick questions for you.`,
        `Are you ready to see how much you qualify for?`
      ],
      options: [
        { id: '7', text: "Yes, Let’s Go!" }
      ],
    },
    {
      id: '23',
      question: [
        `Great! First, do you have Medicare, Medicaid or VA health coverage?`,
      ],
      options: [
        { id: '55', text: "No" },
        { id: '54', text: "Yes" },
      ],
    },
    // If Si, then below question else skip to last
    // show popup for every below option
    {
      id: '17',
      question: [`Which of the following do you have?`],
      specialcss: 'verticleButton',
      options: [
        { id: '39', text: "Medicare" },
        { id: '38', text: "Medicaid" },
        { id: '40', text: "Medicare & Medicaid" },
        { id: '41', text: "VA" },
        { id: '53', text: "Other" },
      ],
    },
    {
      id: '12',
      question: [`Do you make less than $60,000 per year?`],
      final_question: true,
      options: [
        { id: '24', text: "Yes" },
        { id: '25', text: "No" },
        // show popup for no
      ],
    },
    {
      id: 'CALL_CLICK',
      question: [`Congratulations! You’re Pre-Qualified to get up to $6,300 in tax subsidies so you could get Comprehensive Health Insurance at NO COST!! 🤩🤩🥳🥳🥳`,
        `Imagine what you could do with all that savings! `,
        `Tap the Button Below to be connected to a Benefits Agent and see EXACTLY how much you can claim? It’s a free call and only takes a few minutes.`
      ],
      options: [
        { id: '', text: "844-561-3192" },
      ],
    },
  ];