import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-5">
          <footer className="text-center mt-5">
    <div className="container">
      <div className="offset-lg-1 col-lg-10">
        <div className="row mb-3">
          <div className=" py-2">
            <p>
              We are not affiliated or endorsed by any government entity. CMS
              has not reviewed or approved this information. This is not a
              complete description of benefits, visit{" "}
              <a
                className="foot"
                target="_blank"
                href="https://www.healthcare.gov/"
              >
                healthcare.gov
              </a>{" "}
              for a full list of plans and benefits available. Benefits may be
              limited to specific plans and regions. The listed benefits are
              offered as part of some Seguro para Latinos Health Plans &amp;
              Medicare Advantage Plans, enrollment will replace your current
              Healthcare coverage. There is no obligation to enroll.{" "}
            </p>
          </div>
          <div className=" py-2">
            <p>
              *Not all plans or benefits available in all areas. This benefit is
              only available to eligible applicants in the following states: AL,
              AZ, FL, GA, MS, NC, SC, TX, UT, VA. Not all callers from these
              states will qualify for the stated benefit.
            </p>
          </div>
          <div>
          
          </div>
          <div>
            <p>
              © 2013-2022 |{" "}
              <Link target="_blank" to="Privacy-policy">Privacy Policy</Link> 
              |{" "}
              <Link target="_blank" to="Terms-of-use">Terms of Use</Link> 
             {" "}
              
              | <Link target="_blank" to="Cookies-policy" >Cookies Policy</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>

    </div>
  )
}

export default Footer